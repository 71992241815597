import FooterPage from "../components/footer";
import NavBarPage from "../components/navbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useForm, Controller } from "react-hook-form"
import { useState } from "react";
import axios from "axios";
import "./index.css"
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, notification, Space } from 'antd';
const CleantalContactusPage = () => {
    const { control, register, handleSubmit } = useForm();
    const [successMessage, setSuccessMessage] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const openNotificationWithIcon = (type) => {
        api[type]({
          message: 'Success',
          description:
            'Thank you for choosing us',
        });
      };
    const handleonSubmit = (data) => {
        console.log(data)
        const postData = {
            fullName: data.name,
            email: data.email,
            phone: data.phnnumber,
            description: data.message,
            location: data.city
        }
        axios
            .post("https://os.rimstech.in/api/v1/rimswebsite/contactemail", postData )
            .then((response) => {
                if (response.data.message == "Success") {
                    openNotificationWithIcon('success')
                }
            })
    }
    return (
        <>
            <NavBarPage />
            {contextHolder}
            <section class="contact" id="contact">
                <div class="container">
                    <div class="heading text-center">
                        <h3 class="about-h">Get in touch</h3>
                        <p>If you are interested in working with us then please drop us a
                            line, <br />we would love to hear from you.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-4 address-side">
                            <div class="content">
                                <div class="info">
                                    <h4 class="d-inline-block">
                                        Address
                                        <br />
                                        <span>
                                            Prime Consultants and Management Services CC/31/390A, 'Kunnamparampil' Paradise road,Vyttila. P.O COCHIN - 682019. KERALA.</span>
                                    </h4>
                                </div>
                                {/* <!-- Info-2 --> */}
                                <div class="info">
                                    <h4 class="d-inline-block">
                                        Email Us
                                        <br />
                                        <span>
                                            rijas@rijasprime.com</span></h4>
                                </div>
                                {/* <!-- Info-3 --> */}
                                <div class="info">
                                    <h4 class="d-inline-block">
                                        Call Us
                                        <br />
                                        <span>
                                            +919388411292</span></h4>
                                </div>
                                <div class="info">
                                    
                                    <h4 class="d-inline-block">
                                        Other Offices
                                        <br />
                                        <span>
                                            <h6><span class="other-place">Bengaluru</span><br />
                                                <span>Money Chambers, 206, 2nd Floor, KH Road, Double Rd, Shanti Nagar, Bengaluru, Karnataka 560027</span>
                                            </h6>
                                        </span>
                                        <span>
                                            <h6><span class="other-place">Dubai</span><br />
                                                <span>Accruon Consultant LLC, M6-29, Mezzanine Floor, ACICO Business Park, Port Saeed, Deira, Dubai, UAE</span>
                                            </h6>
                                        </span>
                                        <span>
                                            <h6><span class="other-place">USA</span><br />
                                                <span>Glen Allen, Virginia,USA</span>
                                            </h6>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <form class="contact-form" onSubmit={handleSubmit(handleonSubmit)}>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Name</label>
                                        <input type="text" class="form-control" autoComplete="off" placeholder="" {...register('name')} required />
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Email</label>
                                        <input type="text" class="form-control" placeholder="" {...register('email')} required />
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Phone</label>
                                        <Controller
                                            autoComplete="off"
                                            control={control}
                                            name="phnnumber"
                                            rules={{ required: true }}
                                            render={({ field: { ref, ...field } }) => (
                                                <PhoneInput
                                                    class="form-control"
                                                    {...field}
                                                    inputExtraProps={{
                                                        ref,
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    country={"in"}
                                                    autoComplete="off"
                                                    enableSearch={true}

                                                />
                                            )}
                                        />
                                    </div>
                                    
                                    <div class="col-sm-6">
                                        <label>City</label>
                                        <input type="text" class="form-control" autoComplete="off" placeholder="" {...register('city')} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Message(Optional)</label>
                                    <textarea class="form-control" rows="5" id="comment" autoComplete="off" placeholder="Message" {...register('message')}></textarea>
                                </div>
                                {successMessage && <div className="success-msg"> {successMessage} </div>}
                                <button class="btn btn-block" type="submit">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <FooterPage />
        </>
    )
}
export default CleantalContactusPage;