import benefitImage from "../../../assests/images/benefits.png";
import managementTeam from "../../../assests/images/management-team.png";
import cleanN from "../../../assests/images/clean-n.png";
import customer from "../../../assests/images/customers.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const BenefitcleanworldPage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div class="title-wrapper title12 container">
        <h2 class="left h2-b">
          RiMS <span>-Benefits</span>
        </h2>
      </div>
      <section>
        <div class="main-section main-bg2">
          <div class="container">
            <div class="title-wrapper title12">
              <div class="row justify-content-center">
                <div class="col-lg-5 col-md-12 md-pb title-wrapper">
                  <img src={managementTeam} class="mob-img" />
                </div>
                <div class="col-lg-7 col-md-12 top-img center-content">
                  <div class="title-wrapper">
                    <h3>Benefits to Business Owners</h3>
                    <p class="about-p1">
                      <ul class="dot-align">
                        <li>
                          Say Goodbye to restless days and sleepless nights
                        </li>
                        <li>Gain total control over operations</li>
                        <li>Reduced follow-up calls and service complaints</li>
                        <li>
                          Real time update on operations on easy to navigate
                          Dashboard
                        </li>
                        {/* <li>Overview on people management, efficiency and ideal time.</li> */}
                        <li>Reduced dependency on back-office staff</li>
                        <li>Easy monitoring of daily activities</li>
                        <li>Effective cost control</li>
                        <li>Ensures Quality of service in each stage</li>
                        <li>Effective people management</li>
                        <li>Easy and accurate Invoice preparation</li>
                        <li>Paperless documentation</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-section main-bg3">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-12 top-img right-cent">
                <div class="title-wrapper">
                  <h3>Benefits to the Bank Manager</h3>
                  <p class="about-p1">
                    <ul>
                      <li>
                        Easy-to-navigate dashboard with real-time data on the
                        cases
                      </li>
                      <li>
                        Updated status of each case through the mobile app
                      </li>
                      <li>
                        Option to give special instructions to
                        verifier/supervisor through the app
                      </li>
                      <li>
                        Option to view photos and other observations, including
                        the verifier’s voice record, regarding a particular case
                      </li>
                      <li>
                        Bank managers can make decisions based on the data
                        available for each case
                      </li>
                      <li>Managers can download any report at any time</li>
                      <li>
                        Bank managers can substantially reduce calls/follow-ups
                        with RCU agencies
                      </li>
                      <li>
                        Bank managers feel more transparency in agency
                        operations
                      </li>
                      <li>
                        Bank manager can view bank statement/ITR/other documents
                        through the mobile app
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div class="col-lg-7 col-md-12 md-pb">
                <img src={benefitImage} class="mob-img" />
              </div>
            </div>
          </div>
        </div>
        <div class="main-section main-bg4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-12 md-pb title-wrapper">
                <img src={customer} class="mob-img" />
              </div>
              <div class="col-lg-7 col-md-12 top-img center-content">
                <div class="title-wrapper">
                  <h3>Benefits of Verifiers</h3>
                  <p class="about-p1">
                    <ul>
                      <li>Easy-to-navigate dashboard</li>
                      <li>
                        Verifiers in the field have updated information on the
                        cases assigned to him.
                      </li>
                      <li>Notifications on new cases received.</li>
                      <li>Easy uploading of photos /videos/ voice records.</li>
                      <li>
                        Verifiers can complete more verifications in a day due
                        to ideal allocation of cases.
                      </li>
                      <li>
                        Verifiers can complete more verifications in a day due
                        to an ideal allocation of cases.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="title-wrapper">
                <h2 class="left h1-b">
                  Benefits for <span>Agency Managers</span>
                </h2>
                <ul>
                  <li>
                    Accurate data on the number of files logged in for different
                    banks.
                  </li>
                  <li>Verifier-wise data on completed and pending cases</li>
                  <li>
                    Accurate data on the number of verifications completed
                  </li>
                  <li>Effective Tracking of people in the field</li>
                  <li>Easy evaluation of verifier’s performance</li>
                  <li>
                    Tracking of daily traveling of verifiers, along with total
                    distance traveled and meter reading on opening and closing
                  </li>
                  <li>Reports on frauds detected</li>
                  <li>
                    Monthly bank-wise, product-wise MIS available for the
                    preparation of invoices
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 md-pb1">
              <img src={cleanN} class="mob-img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BenefitcleanworldPage;
