
import NavBarPage from "../components/navbar";
import FooterPage from "../components/footer";
import HeaderSectionPage from "./banner";
import AboutFeaturePage from "./aboutfeature";
import BenefitcleanworldPage from "./benefit/benefitpage";
import CustomerTestimonialPage from "./testimonial";
import VideoGalleryPage from "./vidoegallery";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const CleanWorldHomePAge=()=>{
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
    return(<>
    <NavBarPage/>
    <HeaderSectionPage/>
    <AboutFeaturePage />
    <BenefitcleanworldPage/>
    {/* <CustomerTestimonialPage/> */}
    {/* <VideoGalleryPage/> */}
    <FooterPage/>
    </>)
}
export default CleanWorldHomePAge;