import ourfeatures from "../../../assests/images/our-features.png";
import aboutclean from "../../../assests/images/about-clean.png";
const AboutFeaturePage = () => {
  return (
    <>
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="title-wrapper just-content">
                <h2 class="left">
                  About <span>RiMS</span>
                </h2>
                <p class="about-p1">
                  RiMS App – the ultimate solution for your verification
                  business. Twenty-five years of expertise in verification
                  services blended with the latest technology.{" "}
                </p>
                <p>
                  {" "}
                  Risk-related verification is a major service provided to banks
                  in India by RCU agents to control credit risk; hence, it is a
                  very important service. An accurate verification report will
                  enhance the quality of credit evaluation for banks and
                  financial institutions.
                </p>
                <p>
                  RiMS App is a mobile and web-based application specially
                  developed for RCU agents and those in
                  verification/investigation services who take verification
                  service business seriously. While developing the app, our
                  technical team had extensive interactions with many RCU
                  agents, banks, samplers, verifiers, and RCU managers to ensure
                  that the app addresses all practical issues faced by the team
                  in the field and in the back office. This app is designed in
                  such a way that it ensures transparency and quality in each
                  and every stage of service. The app also ensures the
                  management of teams on and off the field and guarantees
                  high-end data security and confidentiality.
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 md-pb1">
              <img src={aboutclean} class="mob-img" />
            </div>
          </div>
        </div>
      </section>
      <section class="main-section main-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-12 md-pb title-wrapper">
              <h2 class="left">
                RiMS <span>-Features</span>
              </h2>
              <img src={ourfeatures} class="mob-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="title-wrapper">
                <p class="about-p1">
                  <div class="uvp-list">
                    <ul>
                      <li>
                        <strong>
                          {" "}
                          System oriented Workflow – Less dependency on people
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Transparent operation - Each process in work flow is
                          visible for respective user
                        </strong>
                      </li>
                      <li>
                        <strong>
                          24 X 7 Working ensured - work from anywhere any time
                          using separate user login
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Easy to Navigate Dashboard displaying the updates in
                          charts/graphs
                        </strong>
                      </li>
                      <li>
                        <strong>
                          All updates available in single touch – No need to ask
                          team for information
                        </strong>
                      </li>
                      <li>
                        <strong>
                          TAT maintenance ensured – Inefficiency reported
                          quickly
                        </strong>
                      </li>
                      <li>
                        <strong>
                          App can manage service to Different Banks by ensuring
                          100% data security
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Automated sending of Verification Reports to Bank
                          Managers
                        </strong>
                      </li>
                      <li>
                        <strong>Tracking of team/case/status possible</strong>
                      </li>
                      <li>
                        <strong>Frequent alerts for new cases</strong>
                      </li>
                      <li>
                        <strong>
                          Automated updates MIS in periodical intervals
                        </strong>
                      </li>
                      <li>
                        <strong>
                        Automatic allocation of cases to verifiers based on pincode
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Notification for each new case logged in for
                          verification
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Quality check ensured before closing of each case –
                          ensures the visit using Google map.
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Bulk upload of cases received through emails
                        </strong>
                      </li>
                      <li>
                        <strong>Automatic Generation of remarks</strong>
                      </li>
                      <li>
                        <strong>
                          Robust security protocols to safeguard sensitive
                          customer data and protect against potential breaches
                          or fraud.
                        </strong>
                      </li>
                    </ul>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutFeaturePage;
