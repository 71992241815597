
// import headerImg from "../../assests/images/about-banner.png";
import aboutRims from "../../assests/images/about/rims-about-banner.png"
import NavBarPage from "../components/navbar";
import FooterPage from "../components/footer";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const TestimoialPageClean=()=>{
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return(<>
    <NavBarPage/>
    <section class="breadcrumb-area1" style={{ backgroundImage: `url(${aboutRims})` }}>
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="inner-content clearfix">
                        <div class="title text-center">
                           <h1>Testimonials</h1>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
    <section class="main-section">
     
        <div class="row justify-content-center">
                    
        
          <div class="col-lg-5 col-md-12 top-img">
              <div class="title-wrapper">
                
                <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
                </iframe>
                 
                
              </div>
          </div>
        
          <div class="col-lg-7 col-md-12 md-pb top-img">
            <h5 class="left h1-b">Allex Allex</h5>
            <h6 class="left h1-b">CEO Cleantel</h6>
            <div class="client-rating">
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
          </div>
            <p class="about-p1">Until recently, the prevailing view 
              assumed lorem ipsum was born as a nonsense text. “It's
               not Latin, though it looks like it, and it actually say
               s nothing,” Before & After magazine answered a curious re
               ader, “Its ‘words’ Syous filler text. In seeing a sample of lorem
                  ipsum, his interest was piqued by consectetur—a genuine, albeit rare, Latin
                   word. Consulting a Latin dictionary led McClintock to a passage frlooks like it, and it actually says nothing,” Before & After magazine answered a curious reader, “Its ‘words’ loosely approximate the frequency with which letters occur in English, which is why at a glance it looks pretty </p>
      <button class="test-btn"><span>Show more</span><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
          </div>
        
        </div>
        </section>
    
    <section class="main-section">
   
        <div class="row justify-content-center">
                    
        
          <div class="col-lg-7 col-md-12 top-img">
              <div class="title-wrapper">
                <h5 class="left h1-b">Allex Allex</h5>
                <h6 class="left h1-b">CEO Cleantel</h6>
                <div class="client-rating">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
              </div>
                <p class="about-p1">Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text.
                   “It's not Latin, though it looks like it, and it actually says nothing,” Before & After
                    magazine answerefter magazine answered a curious re
                    ader, “Its ‘words’ d a curious reader, “Its ‘words’ loosely approximate the frequency with 
                    which letters occur in English, which is why at a glance it looks pretty real.” As Cicero 
                    would put it, “Um, not so fast.” The placeholder text, beginning with the line “Lorem ipsum 
                    dolor sit amet, tock to a passage from De Finibus Bonorum et MalUntil recently, the prevailing 
                     view assumed lorem ipsum was born as a nonsensum was born as a nonsense text. “It's not Latin, though it looks like it,
                     and ittually says nothing,” Before & After magazine answered a curious reader, “Its ‘words’ 
                      loosely approximate the frequency with which letters occur in English, which is why at a glance 
                      it looks pretty </p>
                      <button class="test-btn">Show more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                 
                
              </div>
          </div>
        
          <div class="col-lg-5 col-md-12 md-pb">
           
            <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
            </iframe>
          </div>
        
        </div>
        </section>
    
        <section class="main-section">
      
            <div class="row justify-content-center">
                        
            
              <div class="col-lg-5 col-md-12 top-img">
                  <div class="title-wrapper">
                   
                    <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
                    </iframe>
                     
                  </div>
              </div>
            
              <div class="col-lg-7 col-md-12 md-pb top-img">
                <h5 class="left h1-b">Allex Allex</h5>
                <h6 class="left h1-b">CEO Cleantel</h6>
                <div class="client-rating">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
              </div>
                <p class="about-p1">Until recently, the prevailing view 
                  assumed lorem ipsum was born as a nonsense text. “It's
                   not Latin, though it looks like it, and it actually say
                   s nothing,” Before & After magazine answered a curious re
                   ader, “Its ‘words’ Syous filler text. In seeing a sample of lorem
                      ipsum, his interest was piqued by consectetur—a genuine, albeit rare, Latin
                       word. Consulting a Latin dictionary led McClintock to a passage frlooks like it, and it actually says nothing,” Before & After magazine answered a curious reader, “Its ‘words’ loosely approximate the frequency with which letters occur in English, which is why at a glance it looks pretty </p>
          <button class="test-btn"><span>Show more</span><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            
            </div>
            </section>
  
</div>
   
        {/* <section class="main-section">
      
      <div class="row justify-content-center">
                  
      
        <div class="col-lg-5 col-md-12 top-img">
            <div class="title-wrapper">
             
              <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
              </iframe>
               
              
            </div>
        </div>
      
        <div class="col-lg-7 col-md-12 md-pb top-img">
          <h5 class="left h1-b">Allex Allex</h5>
          <h6 class="left h1-b">CEO Cleantel</h6>
          <div class="client-rating">
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
        </div>
          <p class="about-p1">Until recently, the prevailing view 
            assumed lorem ipsum was born as a nonsense text. “It's
             not Latin, though it looks like it, and it actually say
             s nothing,” Before & After magazine answered a curious re
             ader, “Its ‘words’ Syous filler text. In seeing a sample of lorem
                ipsum, his interest was piqued by consectetur—a genuine, albeit rare, Latin
                 word. Consulting a Latin dictionary led McClintock to a passage frlooks like it, and it actually says nothing,” Before & After magazine answered a curious reader, “Its ‘words’ loosely approximate the frequency with which letters occur in English, which is why at a glance it looks pretty </p>
    <button class="test-btn"><span>Show more</span><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div>
      
      </div>
      </section>
      <section class="main-section">
   
   <div class="row justify-content-center">
               
   
     <div class="col-lg-7 col-md-12 top-img">
         <div class="title-wrapper">
           <h5 class="left h1-b">Allex Allex</h5>
           <h6 class="left h1-b">CEO Cleantel</h6>
           <div class="client-rating">
             <i class="fa fa-star" aria-hidden="true"></i>
             <i class="fa fa-star" aria-hidden="true"></i>
             <i class="fa fa-star" aria-hidden="true"></i>
             <i class="fa fa-star" aria-hidden="true"></i>
             <i class="fa fa-star" aria-hidden="true"></i>
         </div>
           <p class="about-p1">Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text.
              “It's not Latin, though it looks like it, and it actually says nothing,” Before & After
               magazine answerefter magazine answered a curious re
               ader, “Its ‘words’ d a curious reader, “Its ‘words’ loosely approximate the frequency with 
               which letters occur in English, which is why at a glance it looks pretty real.” As Cicero 
               would put it, “Um, not so fast.” The placeholder text, beginning with the line “Lorem ipsum 
               dolor sit amet, tock to a passage from De Finibus Bonorum et MalUntil recently, the prevailing 
                view assumed lorem ipsum was born as a nonsensum was born as a nonsense text. “It's not Latin, though it looks like it,
                and ittually says nothing,” Before & After magazine answered a curious reader, “Its ‘words’ 
                 loosely approximate the frequency with which letters occur in English, which is why at a glance 
                 it looks pretty </p>
                 <button class="test-btn">Show more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
           
           
         </div>
     </div>
   
     <div class="col-lg-5 col-md-12 md-pb">
      
       <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
       </iframe>
     </div>
   
   </div>
   </section>
   <section class="main-section">
      
      <div class="row justify-content-center">
                  
      
        <div class="col-lg-5 col-md-12 top-img">
            <div class="title-wrapper">
              
              <iframe class="video-one" height src="https://www.youtube.com/embed/BuG92IiMCPM?autoplay=1&amp;mute=1&amp;rel=0">
              </iframe>
               
              
            </div>
        </div>
      
        <div class="col-lg-7 col-md-12 md-pb top-img">
          <h5 class="left h1-b">Allex Allex</h5>
          <h6 class="left h1-b">CEO Cleantel</h6>
          <div class="client-rating">
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
        </div>
          <p class="about-p1">Until recently, the prevailing view 
            assumed lorem ipsum was born as a nonsense text. “It's
             not Latin, though it looks like it, and it actually say
             s nothing,” Before & After magazine answered a curious re
             ader, “Its ‘words’ Syous filler text. In seeing a sample of lorem
                ipsum, his interest was piqued by consectetur—a genuine, albeit rare, Latin
                 word. Consulting a Latin dictionary led McClintock to a passage frlooks like it, and it actually says nothing,” Before & After magazine answered a curious reader, “Its ‘words’ loosely approximate the frequency with which letters occur in English, which is why at a glance it looks pretty </p>
    <button class="test-btn"><span>Show more</span><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div>
      
      </div>
      </section> */}
      <FooterPage/>
    </>)
}
export default TestimoialPageClean;