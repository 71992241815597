import NavBarPage from "../navbar";
import FooterPage from "../footer";

import aboutMain from "../../../assests/images/about/rims-about.png";

import vidoe1 from "../../home/vidoegallery/video1.mp4";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const CleanWorldAboutPage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <main className="main">
        <NavBarPage />
        <div>
          <section class="breadcrumb-area1">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="inner-content clearfix">
                    <div class="title text-center">
                      <h1>About RiMS App</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section class="main-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7 col-md-12 top-img">
                <div class="title-about">
                  <h2 class="">
                    RiMS App – the ultimate solution for your verification
                    business. With 25 years of expertise in verification
                    services, blended with the latest technology
                  </h2>
                  <h4>
                    Risk-related verification is a major service provided to
                    banks in India by RCU agents to control credit risk; hence,
                    it is a very important service. An accurate verification
                    report will enhance the quality of credit evaluation for
                    banks and financial institutions.
                  </h4>
                  <p>
                    The RiMS App is a mobile and web-based application specially
                    developed for RCU agents and those in the
                    verification/investigation service who take the verification
                    service business seriously. While developing the app, our
                    technical team had extensive interactions with many RCU
                    agents, banks, samplers, verifiers, and RCU managers to
                    ensure that the app addresses all practical issues faced by
                    the team in the field and in the back office. This app is
                    designed to ensure transparency and quality at every stage
                    of service. The app also ensures the effective management of
                    teams both on and off the field and guarantees high-end data
                    security and confidentiality.
                  </p>
                  <p>
                    This product has been developed and marketed by{" "}
                    <strong>Accruon Technologies Pvt Ltd</strong>
                  </p>
                </div>
                {/* <div class="title-about">
                 
                  
             <p> RiMS App – the ultimate solution for your verification Business, 25 years of expertise in verification service blended with latest technology  - </p>
                                <p> Risk related verification is a major service  to the Banks in India by RCU Agents to control the credit risk hence it is a very important service. Accurate verification report will enhance the quality of credit evaluation of banks/financial institutions.</p>
                                <p>RiMS App is a Mobile and Web based application specially developed for RCU Agents and  those in Verification / Investigation service who looks at verification service Business seriously. While developing the App our technical team had extensive interaction with many RCU agents, Banks, Samplers, verifiers  & RCU managers to ensure that the App addresses all practical issues faced by the team in the field and in the back office.  This App is designed in such a way that it ensures transparency and quality in each and every stage of service.  App also ensure managing of  team on and off the field and also ensures high end data security and confidentiality. </p>
                
              </div> */}
              </div>
              <div class="col-lg-5 col-md-12 md-pb1">
                <img src={aboutMain} class="mob-img" />
              </div>
            </div>
          </div>
        </section>

        <section class="main-section">
          <div class="container">
            <div class="wrap wrap-demo">
              <div class="box-l">
                <div class="boxInner">
                  <div class="circle-1">Fastest</div>
                </div>
              </div>

              <div class="box-l">
                <div class="boxInner">
                  <div class="circle-2">Easiest</div>
                </div>
              </div>
              <div class="box-l">
                <div class="boxInner">
                  <div class="circle-3">Powerful</div>
                </div>
              </div>
              {/* <div class="box-l">
                        <div class="boxInner">
                            <div class="circle-s"> Cleaning Service Management Software</div>
                          
                        </div>
                    </div> */}
            </div>
          </div>
        </section>

        <section class="main-section">
          {/* <div class="container">
                    <div class="row gallery-b">


                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>

                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>

                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                        <div class="col-md-4 video-container-b">
                            <video autoPlay={true} muted loop>
                                <source src={vidoe1} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div> */}
        </section>
        <FooterPage />
      </main>
    </>
  );
};
export default CleanWorldAboutPage;
