

import { useState, useCallback } from "react";
import "../../assests/css/style.css";
import { NavLink } from "react-router-dom";
import { Modal, Button } from 'antd';
import rimsLogo from "../../assests/images/rimslogo.png"
import admin from "../../assests/images/admin.png"
import superVisor from "../../assests/images/supervisor.png"
import bankmanager from "../../assests/images/bankmanager.png"
import "./index.css"

const NavBarPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div class="nav-one">
        <nav class="nav container">
          <input type="checkbox" id="nav__checkbox" class="nav__checkbox" />
          <label for="nav__checkbox" class="nav__toggle">
            <img src="https://img.icons8.com/material-outlined/30/000000/menu--v1.png" class="hamburger" />
            <img src="https://img.icons8.com/plumpy/30/000000/x.png" class="close" />
          </label>
          <a href="#" class="logo-main">
            <img className="rims-log" src={rimsLogo} />
          </a>
          <ul class="nav__menu">
            <li class="active"> <NavLink to="/" className="nav__links ">Home</NavLink></li>
            <li><NavLink to="/about" className="nav__links ">About</NavLink></li>
            <li><NavLink to="/features" className="nav__links ">Features</NavLink></li>
            {/* <li><NavLink to="/testimonials" className="nav__links ">Testimonials</NavLink></li> */}
            <li><NavLink to="/contact-us" className="nav__links ">Contact</NavLink></li>
            <li><div className="nav__links btn head-btn " onClick={showModal}>Login</div></li>
          </ul>
          <Modal open={isModalOpen} onCancel={handleCancel} footer={[]}>
            <div className="login-popup">
              <NavLink to="https://admin.rimstech.in" target="_blank" > <img className="login-image" src={admin} /></NavLink>
              <NavLink to="https://employee.rimstech.in" target="_blank" > <img className="login-image" src={superVisor} /></NavLink>
              <NavLink to="https://verifier.rimstech.in" target="_blank"> <img className="login-image" src={bankmanager} /></NavLink>
            </div>
          </Modal>
        </nav>
      </div>



    </>
  )

}
export default NavBarPage;