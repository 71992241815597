import antifraud from "../../assests/images/features/antifrued.png";
import NavBarPage from "../components/navbar";
import FooterPage from "../components/footer";
import aboutRims from "../../assests/images/about/rims-about-banner.png";
import quality from "../../assests/images/features/quality.png";
import mobileaccess from "../../assests/images/features/mobileaccess.png";
import realverification from "../../assests/images/features/verification.png";
import scalability from "../../assests/images/features/scalability.png";
import securitymess from "../../assests/images/features/security.png";
import rolesperm from "../../assests/images/features/rolepermission.png";
import reporting from "../../assests/images/features/reprtanalysic.png";
import documentmanage from "../../assests/images/features/documentmanage.png";
import notification from "../../assests/images/features/notification.png";
import upload from "../../assests/images/features/upload.png";
import mis from "../../assests/images/features/mis.png";
import navdasboard from "../../assests/images/features/Easy to navigate Dashboard.png";
import easytracking from "../../assests/images/features/Ease of tracking.png";
import tat from "../../assests/images/features/tat.png";
import twentyfour from "../../assests/images/features/24_7.png";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const CleanWorldFeaturePage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <NavBarPage />
      <div>
        <section
          class="breadcrumb-area1"
          style={{ backgroundImage: `url(${aboutRims})` }}
        >
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="inner-content clearfix">
                  <div class="title text-center">
                    <h1>Features of RiMS App</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="main-section">
        <div class="container">
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img
                src={antifraud}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">System-Oriented Workflow</h2>
                <ul>
                  <li>
                    A system-oriented workflow is designed to reduce reliance on
                    individual employees, instead relying on automated
                    processes, well- defined procedures, and efficient
                    technologies to accomplish tasks and achieve goals
                  </li>
                  <li>
                    This approach aims to increase productivity, reduce errors,
                    and ensure consistency in operations.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Transparent Operation</h2>
                <ul>
                  <li>
                    Transparency is considered a fundamental principle of
                    ethical and responsible business conduct
                  </li>
                  <li>
                    All stakeholders can view the status of each initiated case
                    and make decisions based on the available data
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img
                src={mobileaccess}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img
                src={antifraud}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">“24 X 7 Working Ensured</h2>
                <ul>
                  <li>
                    Regardless of your location, simply log in to the app to
                    access the latest real-time data updates
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Easy to Navigate Dashboard</h2>
                <ul>
                  <li>
                    A graphical representation of data and information designed
                    to be intuitive and user-friendly.
                  </li>
                  <li>
                    This dashboard allows users to access and interact with
                    data, metrics, and features efficiently, without confusion
                    or unnecessary complexity.
                  </li>
                  {/* <li>Allow customization of checklists to suit specific cleaning needs</li>
                            <li>Enable cleaners to indicate used consumables, accessories, and equipment on the checklists</li> */}
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img
                src={navdasboard}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img
                src={reporting}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">All Updates Available in A Single Touch</h2>
                <ul>
                  <li>
                    Users of the app need not call any staff to get information;
                    all updates and statuses are available with a single touch
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Timely TAT Maintenance is Ensured.</h2>
                <p>
                  <ul>
                    <li>
                      In the banking/finance industry, completing tasks on time
                      is crucial. This app ensures Turn Around Time (TAT) within
                      the agreed-upon timeframe. Any lapses in meeting TAT will
                      be reported instantly.
                    </li>
                    {/* <li> Automated creation of quotes with subsequent delivery to customers through WhatsApp</li>
                                <li> Customers have the option to approve quotations using WhatsApp</li>
                                <li> Customers will receive an automated invoice generated by the system via WhatsApp</li>
                                <li> WhatsApp messages at the commencement of a task and upon its completion</li>
                                <li> Provide a platform for clients to give feedback and rate the quality of cleaning services</li> */}
                  </ul>
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={tat} style={{ width: "100%" }} class="feature-img" />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img
                src={scalability}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">Providing Service to Multiple Banks</h2>
                <ul>
                  <li>
                    The app allows servicing multiple banks, and cases from
                    different banks can be tracked through a separate menu
                  </li>
                  {/* <li>Create reports detailing worker availability, warehouse and job stock, as well as consumption statistics</li>
                            <li>Generate reports encompassing all inquiries, invoices, and materials utilized for specific jobs</li>
                            <li>Analyze data to identify trends, areas for improvement, and potential resource allocation adjustments</li> */}
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Automated Report Delivery</h2>
                <ul>
                  <li>
                    The app will send updated pending reports, datewise and
                    verifierwise, as well as Verifier performance reports to
                    different user groups based on location (district)
                  </li>
                  <li>
                    The app will send pending reports bankwise, bank statement
                    reports, ITR statements, SLI verifications, supervisor
                    performance reports, and reports not allocated to verifiers
                    to a common user group
                  </li>
                  {/* <li>Implement user authentication, role-based access control</li> */}
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img
                src={securitymess}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img
                src={easytracking}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">Ease of Tracking</h2>
                <ul>
                  <li>
                    The respective user can trace the status of each case.
                  </li>
                  {/* <li>Create reports detailing worker availability, warehouse and job stock, as well as consumption statistics</li>
                            <li>Generate reports encompassing all inquiries, invoices, and materials utilized for specific jobs</li>
                            <li>Analyze data to identify trends, areas for improvement, and potential resource allocation adjustments</li> */}
                </ul>
              </div>
            </div>
          </div>

          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Notifications</h2>
                <ul>
                  <li>
                    The app will provide the following MIS reports for users:
                    Pending Report, Travel Claim Report, Verifier Report,
                    Supervisor Report, Sampler Report, Fraud Report, Document
                    MIS, Vendor MIS, User MIS, Attendance Report, Performance
                    Report, Billing MIS, and Sampler PunchIn Report
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img
                src={notification}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={mis} style={{ width: "100%" }} class="feature-img" />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">MIS </h2>
                <ul>
                  <li>
                    App will send notifications when a new case is assigned and
                    reassigned to verifier.
                  </li>

                  {/* <li>Managers receive notifications when an inspection is scheduled</li>
                            <li>Provides notification when a worker reports any discrepancies in inventory items</li>
                            <li>Receive a notification in case any user's documents expire</li> */}
                </ul>
              </div>
            </div>
          </div>
          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Automated Assignment of Cases</h2>
                <p>
                  <ul>
                    <li>
                      When a new case is entered into the app, it will be
                      automatically assigned to the appropriate verifiers based
                      on the provided pincode for verification.
                    </li>
                    {/* <li> Automated creation of quotes with subsequent delivery to customers through WhatsApp</li>
                                <li> Customers have the option to approve quotations using WhatsApp</li>
                                <li> Customers will receive an automated invoice generated by the system via WhatsApp</li>
                                <li> WhatsApp messages at the commencement of a task and upon its completion</li>
                                <li> Provide a platform for clients to give feedback and rate the quality of cleaning services</li> */}
                  </ul>
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img
                src={scalability}
                style={{ width: "100%" }}
                class="feature-img"
              />
            </div>
          </div>

          <div class="row justify-content-center content-top">
            <div class="col-lg-5 col-md-12 img-left">
              <img src={upload} style={{ width: "100%" }} />
            </div>
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title2">
                <h2 class="">Facilitate Bulk Upload of Cases</h2>
                <ul>
                  <li>
                    The app can upload cases individually or in bulk, saving
                    time on manual data entry
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row justify-content-center content-top">
            <div class="col-lg-7 col-md-12 top-img">
              <div class="features-title">
                <h2 class="">Security</h2>
                <ul>
                  <li>
                    Strong security measures are in place to protect customer
                    data and prevent breaches or fraud.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <img src={securitymess} style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </section>
      <FooterPage />
    </>
  );
};
export default CleanWorldFeaturePage;
